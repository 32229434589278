import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Card, Icon, Message} from 'semantic-ui-react';
import {ExperienceFormImageCard} from "./ExperienceFormImageCard";
import {updateLocalExperienceValue} from "../../../../../redux/experienceDucks";

export const ExperienceFormImages = () => {
    // A selector for retrieving the images of the experience from the store
    let images = useSelector(store => store.experiences.experience.value.images);

    // A dispatcher for dispatching actions to the store
    const dispatch = useDispatch();

    const handleImageInputChange = async (event) => {
        if (!event.target.files) return;
        // Push the image URLs to the experience
        let newImages = Array.from(event.target.files).map(file => {
            return {
                pictureUrl: URL.createObjectURL(file),
            }
        });
        // Push the images to the list
        images = await images.setValue([
            ...images.value,
            ...newImages,
        ])
        // Dispatch the action to update the experience
        dispatch(updateLocalExperienceValue({images}));
    };

    /**
     * Deletes the image at the given index.
     * @param index The index of the image to delete.
     */
    async function handleImageDelete(index) {
        const image = images.value[index];
        let newImages;
        if (image.id) {
            newImages = [...images.value];
            newImages[index] = {
                id: image.id,
                _destroy: true,
            }
            images = await images.setValue(images.value);
        } else {
            newImages = [
                ...images.value.slice(0, index),
                ...images.value.slice(index + 1),
            ]
        }
        await images.setValue(newImages);
        dispatch(updateLocalExperienceValue({images}));
    }

    /**
     * Handles the image input click event.
     * Clears the input value each time the user clicks on the input.
     * This allows the user to upload the same image multiple times.
     * @param event The event of the image input click.
     */
    function handleImageInputClick(event) {
        event.target.value = null;
    }

    async function handleImagePrimaryToggle(index) {
        // Toggle the primary in the image at the given index.
        // If the image is already primary, then remove the primary.
        // If the image is not primary, then set it as primary.
        // If another image is primary, then remove the primary from it.
        // Set the images in the state.
        // Dispatch the action to update the experience.
        images = await images.setValue(images.value.map((image, i) => {
            const newImage = {
                ...image,
                primary: index === i ? !image.primary : false,
            };
            if (newImage.id) {
                newImage._update = true;
            }
            return newImage
        }));
        dispatch(updateLocalExperienceValue({images}));
    }

    const renderImages = () => {
        let nodes = [];
        for (let i = 0; i < images.value.length; i++) {
            const image = images.value[i];
            if (image._destroy) {
                continue;
            }
            nodes.push(
                <ExperienceFormImageCard
                    key={i}
                    imageIndex={i}
                    imageUrl={image.pictureUrl}
                    onImageDelete={handleImageDelete}
                    onImagePrimaryToggle={handleImagePrimaryToggle}
                    primary={image.primary}
                />
            );
        }
        return nodes;
    };

    return <>
        {
            images.hasError() && <Message error visible>
                {images.error}
            </Message>
        }
        <Card.Group
            itemsPerRow={3}
            style={{
                marginLeft: "0px",
                marginRight: "0px"
            }}
        >
            {/*
            An image card for each image in the experience
            */}
            {
                renderImages()
            }
            <Card
                style={{
                    height: '96px',
                    width: '96px',
                    borderRadius: '20px',
                    backgroundColor: 'rgba(132, 132, 132, 0.4)',
                    boxShadow: 'none',
                    marginLeft: "4px",
                    marginRight: "4px"
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Button
                        as={'label'}
                        htmlFor={'image-input'}
                        type={'button'}
                        circular
                        style={{
                            backgroundColor: '#00b5ad',
                        }}
                        icon
                    >
                        <Icon name={'plus'} inverted/>
                    </Button>
                    <input
                        id={'image-input'}
                        type="file"
                        hidden
                        multiple
                        accept="image/jpeg, image/png"
                        onChange={handleImageInputChange}
                        onClick={handleImageInputClick}
                    />
                </div>
            </Card>
        </Card.Group>
    </>;
};
